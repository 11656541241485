.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 6rem;

  img {
    max-height: 6rem;
  }

  span {
    font-size: 2.6rem;
    font-weight: 900;
    margin-left: 2rem;
    transition: opacity .5s ease-in-out, width .5s ease-in-out, color .2s ease-in-out;
  }

  &--dark {
    margin: 2rem auto;
    background: none;

    img {
      max-height: 3.5rem;
    }

    span {
      font-size: 1.4rem;
      font-weight: 900;
      color: $white;
      margin-left: 1.5rem;
    }

    &:hover {
      cursor: pointer;

      span {
        color: $primary;
      }

      a {
        text-decoration: none;
      }
    }
  }

  &--collapsed {
    span {
      opacity: 0;
      width: 0;
      margin: 0;
      overflow: hidden;
    }
  }
}
