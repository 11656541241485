.article {

  &__action {
    display: flex;
    align-items: center;
    margin-top: 2rem;

    &--center {
      display: flex;
      justify-content: center;
      margin-top: 4rem;
    }
  }

  &__input {
    margin-bottom: 2rem;
  }

 .card {
   margin-bottom: 2rem;

   .ant-card-head {
     padding: 0 10px;
     min-height: 3rem;
     display: flex;
     align-items: center;
   }

   .ant-card-head-title {
     padding: 0;
     font-size: 1.2rem;
   }

   .ant-card-body {
     padding: 1.5rem 2.5rem;
     touch-action: manipulation;
     transition: border .3s ease;

     &:hover, &:focus, &:active {
       border: #1890ff 1px solid;
     }
   }
 }
}
