.header {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 0 1.5rem;

  .user {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 6.4rem;

    &__name {
      max-height: 25px;
      margin-top: -1rem;
    }

    &__logout {
      margin-top: 1.5rem;
    }
  }
}
