@import "vendor/reset.css";
@import "~antd/dist/antd.css";
@import "_vars.scss";

html {
  font-size: 62.5%;
}

body {
  font-size: 1.4rem;
  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.no-scroll {
    overflow: hidden;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

h1 {
  font-size: 2.4rem;
  font-weight: 900;
}

.loading {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.layout {
  padding: 24px;
  min-height: 360px;

  &--white {
    background: $white;
  }

  &--no-padding {
    padding: 0;
  }
}

/* Blocks */
@import "blocks/logo";
