.user {
  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 2rem;
  }

  &__alert {
    margin-bottom: 3rem;
  }
}
