@import "src/styles/vars";

.articles {
  &__action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 0 2rem;
  }

  &__image {
    padding: 2rem;
    border: 1px solid #eff1f4;
    border-radius: .5rem;
    img {
      width: 15rem;
      height: 15rem;
      object-fit: contain;
    }
  }

  &__description {
    padding: 0 2rem;
  }

  &__card-body {
    display: flex;
  }
}
