@import "../../styles/vars";

.auth {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $gray;

  &__container {
    width: 40rem;
  }

  &__action {
    padding-top: 1.5rem;
    display: flex;
    justify-content: center;
  }

  &__alert {
    margin-bottom: 3rem;
  }
}
